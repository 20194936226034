<template>
  <div class="row">
    <div class="col-12 col-lg-6 c g">
        <div class="card card-body">
            <h2 class="text-center">
                <img :src="require('@/assets/images/ai.png')" style="width: 60px">
                دِربكش AI !
            </h2>
            <h4 class="text-center">
                نظام ذكاء اصطناعي للرد على عملائك على الواتساب. 
            </h4>
            <div class="row g">
                <div class="col-12 col-lg-3 g text-center">
                    <img :src="require('@/assets/images/chat.png')" alt="" style="width: 65px">
                    <br>
                    <i class="fa fa-check text-success"></i>
                    فهم الرسائل النصية<br>والمناورة في الكلام
                </div>
                <div class="col-12 col-lg-3 g text-center">
                    <img :src="require('@/assets/images/micrphone.png')" alt="" style="width: 65px">
                    <br>
                    <i class="fa fa-check text-success"></i>
                    فهم الرسائل الصوتية<br>والرد بسرعة كبيرة
                </div>
                <div class="col-12 col-lg-3 g text-center">
                    <img :src="require('@/assets/images/link.png')" alt="" style="width: 65px">
                    <br>
                    <i class="fa fa-check text-success"></i>
                    ربط المحادثات API<br>بنظامك الخاص
                </div>
                <div class="col-12 col-lg-3 g text-center">
                    <img :src="require('@/assets/images/images.png')" alt="" style="width: 65px">
                    <br>
                    <i class="fa fa-check text-success"></i>
                    فهم الصور <br>(قريباً ان شاء الله)
                </div>
            </div>
        </div>
        <div class="card card-body">
            <b-form-checkbox v-model="allowed" name="check-button" switch>
                <h4>
                    تفعيل دِربكش AI
                </h4>
            </b-form-checkbox>
            <div class="form-group" v-if="allowed">
                <br>
              <h5 for="">التعلميات (اشرح له نظامك بشكل جيد وبأسلوب تفصيلي)
              </h5>
              <textarea class="form-control" v-model="instructions" rows="5" :placeholder="`النظام الخاص بي عبارة عن كذا\nواذا سألك شخص عن كذا اخبره بكذا\nوتحدث باللهجة الخليجية الخ...`"></textarea>
            </div>
            <div>
                <button v-for="x in [1,2,3,4,5,6,7,8]" style="border-radius: 0px" :key="x" @click="count = x" :class="`btn btn-sm ` + (count >= x ? 'btn-primary' : 'btn-outline-secondary')">{{x}}</button>
            </div>
            <div class="form-group" v-if="allowed && count >= 2">
                <br>
              <h5 for="">التعلميات (اشرح له نظامك بشكل جيد وبأسلوب تفصيلي)</h5>
              <textarea class="form-control" v-model="instructions2" rows="5" :placeholder="`النظام الخاص بي عبارة عن كذا\nواذا سألك شخص عن كذا اخبره بكذا\nوتحدث باللهجة الخليجية الخ...`"></textarea>
            </div>
            <div class="form-group" v-if="allowed && count >= 3">
                <br>
              <h5 for="">التعلميات (اشرح له نظامك بشكل جيد وبأسلوب تفصيلي)</h5>
              <textarea class="form-control" v-model="instructions3" rows="5" :placeholder="`النظام الخاص بي عبارة عن كذا\nواذا سألك شخص عن كذا اخبره بكذا\nوتحدث باللهجة الخليجية الخ...`"></textarea>
            </div>
            <div class="form-group" v-if="allowed && count >= 4">
                <br>
              <h5 for="">التعلميات (اشرح له نظامك بشكل جيد وبأسلوب تفصيلي)</h5>
              <textarea class="form-control" v-model="instructions4" rows="5" :placeholder="`النظام الخاص بي عبارة عن كذا\nواذا سألك شخص عن كذا اخبره بكذا\nوتحدث باللهجة الخليجية الخ...`"></textarea>
            </div>
            <div class="form-group" v-if="allowed && count >= 5">
                <br>
              <h5 for="">التعلميات (اشرح له نظامك بشكل جيد وبأسلوب تفصيلي)</h5>
              <textarea class="form-control" v-model="instructions5" rows="5" :placeholder="`النظام الخاص بي عبارة عن كذا\nواذا سألك شخص عن كذا اخبره بكذا\nوتحدث باللهجة الخليجية الخ...`"></textarea>
            </div>
            <div class="form-group" v-if="allowed && count >= 6">
                <br>
              <h5 for="">التعلميات (اشرح له نظامك بشكل جيد وبأسلوب تفصيلي)</h5>
              <textarea class="form-control" v-model="instructions6" rows="5" :placeholder="`النظام الخاص بي عبارة عن كذا\nواذا سألك شخص عن كذا اخبره بكذا\nوتحدث باللهجة الخليجية الخ...`"></textarea>
            </div>
            <div class="form-group" v-if="allowed && count >= 7">
                <br>
              <h5 for="">التعلميات (اشرح له نظامك بشكل جيد وبأسلوب تفصيلي)</h5>
              <textarea class="form-control" v-model="instructions7" rows="5" :placeholder="`النظام الخاص بي عبارة عن كذا\nواذا سألك شخص عن كذا اخبره بكذا\nوتحدث باللهجة الخليجية الخ...`"></textarea>
            </div>
            <div class="form-group" v-if="allowed && count >= 8">
                <br>
              <h5 for="">التعلميات (اشرح له نظامك بشكل جيد وبأسلوب تفصيلي)</h5>
              <textarea class="form-control" v-model="instructions8" rows="5" :placeholder="`النظام الخاص بي عبارة عن كذا\nواذا سألك شخص عن كذا اخبره بكذا\nوتحدث باللهجة الخليجية الخ...`"></textarea>
            </div>
        </div>
        <div class="card card-body">
            <b-modal id="modal-1" title="AI HTTP" hide-footer>
                <template v-if="current._id">
                    <div class="form-group">
                      <h5 for="">العنوان</h5>
                      <input type="text"
                        class="form-control" v-model="current.title" placeholder="العنوان هنا...">
                    </div>
                    <div class="form-group">
                      <h5 for="">التعليمات والوصف التفصيلي للموضوع واذكر المتغيرات ونوع كل واحد وكل شيئ</h5>
                      <textarea class="form-control" rows="5" placeholder="اكتب هنا..." v-model="current.instructions"></textarea>
                    </div>
                    <div class="form-group">
                      <label for="">URL (POST)</label>
                      <input type="text"
                        class="form-control" v-model="current.url" placeholder="URL HERE...">
                    </div>
                    <div class="form-group">
                      <h5 for="">رسالة النجاح (ضع response_message اذا كنت تريد الرد بمحتوى الاستعلام)</h5>
                      <textarea class="form-control" v-model="current.success" placeholder="اكتب هنا..." rows="3"></textarea>
                      <small>اذا كنت تريد الرد بملف في الرسالة اكتب في رد الموقع FILE=http://example...</small>
                    </div>
                    <div class="form-group">
                      <h5 for="">رسالة الفشل</h5>
                      <textarea class="form-control" v-model="current.error" placeholder="اكتب هنا..." rows="3"></textarea>
                    </div>
                    <div class="card card-body border">
                        <div class="col-12 g">
                            <button class="btn btn-sm btn-primary" @click="current.inputs.push({
                                variable: '',
                                title: '',
                                code: Math.random().toString().split('.')[1]
                            })">
                                <i class="fa fa-plus"></i>
                                اضافة متغير
                            </button>
                        </div>
                        <table class="table table-sm table-bordered table-hover">
                            <thead>
                                <th>
                                   English Variable
                                </th>
                                <th>
                                   اسم الحقل
                                </th>
                                <th>
                                    حذف
                                </th>
                            </thead>
                            <tbody>
                                <tr v-for="input in current.inputs" :key="input.code">
                                    <td>
                                        <input type="text" v-model="input.variable" placeholder="name,phone,etc...">
                                    </td>
                                    <td>
                                        <input type="text" v-model="input.title" placeholder="الاسم، الجوال، الخ...">
                                    </td>
                                    <td>
                                        <button class="btn btn-sm btn-danger" @click="current.inputs=current.inputs.filter(function(x){
                                            if(input.code != x.code){
                                                return x;
                                            }else{
                                                return false;
                                            }
                                        })">
                                            <i class="fa fa-trash"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-12 text-center">
                        <button class="btn btn-primary" @click="addNow()">
                            <i class="fa fa-check"></i>
                            حفظ
                        </button>
                    </div>
                </template>
            </b-modal>
            <div class="col-12 table-responsive" style="min-height: 0px">
                <button class="btn btn-primary" v-b-modal.modal-1 @click="edit=false;current = {inputs: [], title: '', _id: Math.random().toString().split('.')[1], instructions: `اذا اراد الاستعلام عن اسمه
فاطلب منه المتغيرات ورد عليه`, success: 'response_message', error: 'برجاء اعادة المحاولة'}">
                    <i class="fa fa-link"></i>
                    إضافة AI HTTP
                </button>
                <table class="table table-sm table-hover table-bordered" style="margin-top: 10px" v-if="https.length">
                    <thead>
                        <th>
                            العنوان
                        </th>
                        <th>
                            ادارة
                        </th>
                        <th>
                            حذف
                        </th>
                    </thead>
                    <tbody>
                        <tr v-for="http in https" :key="http.title">
                            <td>
                                {{ http.title }}
                            </td>
                            <td>
                                <button class="btn btn-sm btn-primary" @click="current = http; edit=true" v-b-modal.modal-1>
                                    <i class="fa fa-edit"></i>
                                    ادارة
                                </button>
                            </td>
                            <td>
                                <button class="btn btn-sm btn-danger" @click="https=https.filter(function(x){
                                    if(http._id != x._id){
                                        return x;
                                    }else{
                                        return false;
                                    }
                                })">
                                    <i class="fa fa-trash"></i>
                                </button>
                            </td>
                        </tr> 
                    </tbody>
                </table>
            </div>
        </div>
        <div class="card card-body">
            <div class="g">
                <b-form-checkbox v-model="need_real" name="check-button">
                    اذا اراد شخص التواصل مع خدمة العملاء او شخص حقيقي فلا ترد عليه لباقي اليوم.
                </b-form-checkbox>
            </div>
            <div class="g">
                <b-form-checkbox v-model="send_voice" name="check-button">
                    الرد برسالة صوتية اذا كان الرد اقل من 100 حرف واذا لم يكن في الرد ارقام او روابط. (لا تفعل هذا الخيار مع الخيار الثالث "ايقاف الرد الآلي...")
                </b-form-checkbox>
            </div>
            <div class="g">
                <b-form-checkbox v-model="stop_on_send" name="check-button">
                    ايقاف الرد الآلي اذا قمت بارسال رسالة من الواتس اب لمدة 15 دقيقة للرقم المستهدف. (يجب ان تكون رسائلك المرسلة تتضمن كلمة "رد آلي")
                </b-form-checkbox>
            </div>
            <div class="g">
                <b-form-checkbox v-model="allow_groups" name="check-button">
                    الرد على المجموعات.
                </b-form-checkbox>
                <br>
            </div>
            <div class="form-group">
              <h5 for="">منع تفعيل الذكاء الاصطاعي على اجهزة معينة</h5>
              <textarea class="form-control" v-model="blocked_devices" placeholder="اكتب رقم الجهاز هنا مثل:  67bdc2791d76868ec4057be3" rows="3"></textarea>
            </div>
            <div class="col-12 text-center">
                <button class="btn btn-primary" @click="save()">
                    <i class="fa fa-check"></i>
                    حفظ التعديلات
                </button>
                <br><br>
                <small>اذا قمت بالحفظ الذكاء الاصطناعي سينسى المحادثات السابقة بينه وبين العملاء.</small>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { BDropdown, BDropdownItem, BFormCheckbox } from "bootstrap-vue";
import { BOverlay, BModal, VBModal } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
    components: {
        BDropdown,
        BDropdownItem,
        BFormCheckbox,
        BModal,
    },
    directives: {
        Ripple,
        "b-modal": VBModal,
    },
    data(){
        return {
            allowed: false,
            current: {},
            edit: false,
            instructions: ``,
            instructions2: ``,
            allow_groups: false,
            blocked_devices: "",
            instructions3: ``,
            instructions4: ``,
            instructions5: ``,
            instructions6: ``,
            instructions7: ``,
            instructions8: ``,
            count: 1,
            https: [],
            need_real: false,
            send_voice: false,
            stop_on_send: false,
            jwt: JSON.parse(localStorage.getItem("user")).jwt,
        }
    },
    created(){
        var g = this;
        $.post(api + '/user/ai/config', {
            jwt: g.jwt
        }).then(function(r){
            if(r.status != 100){
            }else{
                g.allowed = r.response.allowed
                g.instructions = r.response.instructions
                g.instructions2 = r.response.instructions2
                g.instructions3 = r.response.instructions3
                g.instructions4 = r.response.instructions4
                g.instructions5 = r.response.instructions5
                g.instructions6 = r.response.instructions6
                g.instructions7 = r.response.instructions7
                g.instructions8 = r.response.instructions8
                g.need_real = r.response.need_real
                g.send_voice = r.response.send_voice;
                g.stop_on_send = r.response.stop_on_send
                g.https = JSON.parse(r.response.https);
                g.blocked_devices = r.response.blocked_devices;
                g.allow_groups = r.response.allow_groups;
            }
        }).fail(function(){
            alert("حدث مشكلة في الاتصال")
        })
    },
    methods: {
        addNow(){
            var g = this;
            if(!g.edit){
                g.https.push(g.current)
            }
            $("#modal-1___BV_modal_header_ > button").click()
        },
        save(){
            var g = this;
            $.post(api + '/user/ai/save', {
                jwt: g.jwt,
                allowed: g.allowed,
                instructions: g.instructions,
                instructions2: g.instructions2,
                instructions3: g.instructions3,
                instructions4: g.instructions4,
                instructions5: g.instructions5,
                instructions6: g.instructions6,
                instructions7: g.instructions7,
                instructions8: g.instructions8,
                need_real: g.need_real,
                send_voice: g.send_voice,
                stop_on_send: g.stop_on_send,
                allow_groups: g.allow_groups,
                blocked_devices: g.blocked_devices,
                https: JSON.stringify(g.https)
            }).then(function(r){
                if(r.status != 100){
                }else{
                    alert("تم الحفظ بنجاح", 100)
                }
            }).fail(function(){
                alert("حدث مشكلة في الاتصال")
            })
        }
    }
}
</script>

<style>

</style>